.redirectDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.redirectLogo {
    width: 3.75rem;
    height: 3.75rem;
}
.redirectLogo h1 {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 600;
    line-height: 2.375rem;
    width: 11.375rem;
}
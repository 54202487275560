.body-terms {
  width: 100%;
  height: 100%;
  padding: 3rem;
  background-color: #131313;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text a {
  color: #4d80d9;
}
.return-button a {
  text-decoration: none;
  color: white;
}
#return-button {
  width: 99%;
  display: flex;
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
}
.content {
  width: 55%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text p {
  margin-left: 25px;
}
.text h1 {
  font-size: 24px;
  padding-bottom: 10px;
}
.body-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  flex-direction: column;
  padding-bottom: 50px;
}

.body-header p {
  padding: 5px 0;
}

.body-header img {
  width: auto;
  height: 3rem;
  color: white;
  margin-bottom: 23px;
}

.updated-text {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  width: 100%;
  padding: 3rem 0;
}

@media (max-width: 720px) {
  .content {
    width: 90%;
  }
}

@media (max-width: 440px) {
  .content {
    width: 95%;
  }
}

@media (max-width: 370px) {
  .content {
    width: 80%;
  }
  .body-terms {
    padding: 1rem;
  }
}

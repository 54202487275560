.verifyPage{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.loadingText {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadingText h2{
    margin-top: 1.5rem;
    color: #4B4B4B;
    font-size: 2rem ;
}

.verifiedText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50rem;
}
.verifiedText h1 {
    margin-top: 1.5rem;
    text-align: center;
    color: #4B4B4B;
    font-size: 2.4rem;
}


.verifiedText h2 {
    margin-top: 1.5rem;
    text-align: center;
    color: #8A8A8A;
    font-size: 1.3rem;
}




.layout{
    display: flex;
    align-items: center;
    background-color: #F4F7FE;
}

.Content {

    margin-top: 2rem;
    height: 110%;
    background-color: #F4F7FE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100vw - 12.5rem);
    margin-left: 12.5rem;
    padding:1rem;
}


@media only screen and (min-width: 1600px){
    .Content {
        width: calc(100vw - 15%);
        /*width: 100vw;*/
        background-color: #F4F7FE;
        margin-left: 12.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.mainLayouts {
    background-color: #fed201;
}

.button{
padding: 10px 16px;
background: linear-gradient(269.6deg, #2561F9 0.35%, #0095FF 99.76%);
border-radius: 5px;
color:white;
}
.phoneContainer {
    position: relative;
    top:20px;
    width: 400px;
    height: 730px;
    background-image: url("../assets/images/Silver.png");
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    display: flex;
}

@media screen and (max-width: 2000px) {
   .card{
      justify-content: center;
   }

}
@media screen and (max-width: 1200px){
    .phoneContainer{
      display: none;
     }
     .imageContainer{
     display: none;
     }
    
}
.App {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .fileUploadBox {
   
    width: 661px;
    height: 200px;
    border:0px;
    border-color: #AFA4A4;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23CAC3C3FF' stroke-width='3' stroke-dasharray='14%2c 14' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e");
border-radius: 10px;
 
  }
  .uploadImageBox {
    position: absolute;
    top: 16px;
    object-fit: cover;
    border-radius: 30px;
    left: 47px;
    width: 325px;
    height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .dragging {
    background-color: #eeeeee;
  }
  

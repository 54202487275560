.sourcesList {
    margin-top: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 90vh;
}
.sourcesCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.addArticleButton {
    width: 6.688rem;
    height: 2.438rem;
    margin: 1rem;
    border: none;
    background: #2561F9;
    border-radius: 0.625rem;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0.2rem;
}

.addArticleButton h2{
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 20px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
}

.faPlus {
    color: white;
    width: .8rem;
    height: .8rem;
}

.horizontalLine {
    margin-top: 1rem;
    width: 100%;
    border: 0.031rem solid #DBDBDB;
}

.loadingText {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
}

.sourcesCardHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
}

.sourcesListTitles{
    display: flex;
    flex-direction: row;
    align-items: center;

}

.sourcesListTitle{
    margin-left: .8rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    letter-spacing: 0;
    text-align: center;
}

.sourcesListSubtitle{
    margin-left: 2.5rem;
    font-size: .813rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0;
    text-align: center;
}


.actionButtons{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    flex-direction: row;
}

.addSource{
    background-color: #2561F9;
}

.sliderTest {
    background-color: red;
}

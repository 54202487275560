.drawerDiv{
    display: flex;
    align-items: center;
    flex-direction: column;

}


.drawerContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    border-radius: 0.2rem;
    padding: 1rem;
}

.drawerContent:hover {
    cursor: pointer;
    background-color: #2554D8;
}

.drawerContent h2 {
    font-family: "Cabinet Grotesk";
    font-weight: 500;
    font-size: 1.25rem;
    color: white;
}



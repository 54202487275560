.mainWrapper {
    min-height: 100vh;
    width: 100%;
    background-color: #000000;
}

.logoHeader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3%;
    align-items: center;
    padding-top: 5%;
    align-self: center;
    padding-left:5%;
    padding-right: 5%;
}
.rightSide{
    justify-content: center;
    align-self: flex-end;
}

.mainTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}

.mainTitle h2 {
    font-family: "Cabinet Grotesk";
    font-size: 2rem;
    color: white;
    font-weight: 800;
}

.menuStyle {
    font-family: "Cabinet Grotesk";
    background-color: #000000;
    color: white;
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    gap: 2rem
}


.rightSideIcon:hover {
    cursor: pointer;
    fill: #818181;
}

.menuStyle :global(.ant-menu-title-content) {
    transition: color 0.3s;
    color: white;
}


@media screen and (min-width: 2px) and (max-width: 990px) {
    .menuStyle {
        justify-content: center;
        font-size: 0.85rem;
        gap: 0rem;
    }
}
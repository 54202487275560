.Header {
    position: fixed;
    height: 4.2rem;
    background-color: white;
    width: calc(100vw - 12.5rem);
    margin-left: 12.5rem;
    padding-right: 4rem;


    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    z-index: 50;
}

.dropDownList {
    margin-top: 6rem;
    border: 1px solid #FFFFFF;
    background-color: #DBDBDB;
}

.dropDownList li{
    list-style: none;
    margin-top: 0.1rem;
    padding: 0.2rem;
}

.dropDownList h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */
    text-align: center;
    color: #2561F9;
}


.logIn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.logIn h1 {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2.375rem;
    width: 11.375rem;
}

.logInLogo {
    width: 3.75rem;
    height: 3.75rem;
}

.logInButton {
    margin-top: 3.125rem;
    height: 3.813rem;
    width: 20rem;
    top: 23.25rem;
    border-radius: 0.938rem;
    background-color: #2D60E3;
    color: #FFFFFF;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 0.938rem;
    line-height: 1.375rem;
    cursor: pointer;
}




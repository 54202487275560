

.shareSocialMedia{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.shareButton {
    cursor: pointer;
}
.modalIcon{
    display: flex;
    align-items: center;
    height: 1.6rem;
    width: 1.6rem;
}

.modalIcon :hover{
    cursor: pointer;
    background: white;
}
.shareUrlInput{
   display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.shareUrlInput {
    width: 100%;
}

.shareUrlInput :hover{
    cursor: pointer;
}

.shareModal {
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.shareModalTitle h2{
    font-family: "Cabinet Grotesk";
    font-size: 1.7rem;
    text-align: center;
}
.shareUrlInput :global(.ant-input-suffix){
    color: black;
}

.shareUrlInput :global(.ant-input-affix-wrapper-disabled){
    border: none;
    line-height: 2rem;
}

.shareUrlInput h3{
    font-family: "Cabinet Grotesk";
    font-size: 1.1rem;
    font-weight: 300;
}
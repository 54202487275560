.phoneContainer {
    position: relative;
    width: 320px;
    height: 600px;
    background-image: url("../../assets/images/Silver.png");
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    display: flex;
}
 .modalStyle .ant-modal-footer {
 background-color: red;
}

.imageContainer {
    position: absolute;
    /* object-fit: cover; */
    top: 80px;
    border-radius: 30px;
    left: 60px;
    width: 270px;
    height: 590px;
    background-size: cover;
    background-repeat: no-repeat;
}
.cardModal{
    width: 300px;
    height: 70px ;
    overflow: hidden;
/* white-space: nowrap; */
    border-radius:0 ;
    background-color:#F0F4FC ;
    margin-bottom:30px;
    padding-bottom: 30px;
    font-size: 14px;
   
}
.cardPackage{
    width:180px;
    border: 1px solid #0095FF ;
    border-radius:2 ;
}
.divCards{

    display:grid;
    float: right;
    justify-content:space-evenly;
    margin-top:60px;
     justify-content: space-evenly;
     margin-right: 30px;

}

p{
    font-weight:200;
    font-size:15px;
}
h4{
    font-weight:500;
    font-size:18px;
}
.modalStyle .ant-modal-content{
    background-color: #01579b;
 }
.tableWrapper {
    width: 100%;
}

.tableWrapper::-webkit-scrollbar{
    display: none;
}

table {
    width: 100%;
    border-spacing: 0;

}

table img {
    width: 65px;
    height: 65px;
}

table td, table th {
    padding: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table tr th h2 {

    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.25rem;
    /* identical to box height */
    text-align: center;
    color: #757575;

}
.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tableImg{
    width: 4rem;
    height: 4rem;
}

.tdLine {
    width: 1rem;
}

.ant-table {
    font-size: 5px;
}

/* row data */
.ant-table-tbody > tr > td {
    height: 5px;
    padding: 5px;
}

.ant-table-thead > tr > th {
    height: 5px;
    padding: 4px;
    
}
@media screen and (max-width: 800px) {
    .imageContainer {
        margin-top: 90px;
        left: 50px;
    width: 210px;
    height: 440px;
     }
     .phoneContainer{
        margin-top: 90px;
        width: 240px;
    height: 450px;
     }
     .cardModal{
        width: 250px;
        height: 55px ;
        margin-right: 50px;
        float: right;
        text-align:center;
     }
   }
   @media screen and (max-width: 650px){
    .phoneContainer{
      display: none;
     }
     .imageContainer{
     display: none;
     }
     .divCards{
        justify-content: center;
        margin-right: 50px;
        
    }

   }

.body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;;
    font-size: 1rem;
    text-align: center;
    padding: 1.5rem;
}



.inputArea{
    resize: none;
    border-radius: 0.2rem;
    width: 45rem;
    height: 3rem;
    padding: 0.2rem;
    margin-right: 0.5rem;
}

.addCategoriesForm{
    padding-left: 1rem;
    display: flex;
    align-items: flex-start;
}

.inputArea{
    resize: none;
    border-radius: 0.2rem;
    width: 45rem;
    height: 3rem;
    padding: 0.2rem;
    margin-right: 0.5rem;
}

.fileInput Button {
    border-radius: 0.3rem;
    background-color: #2561F9;;
}

.fileInput Button span{
    color: white;
}


.formItem{
    width: 45rem;
    height: 4rem;

}

.formItem Input{
    padding-left: 1rem;
    height: 4rem;
    border-radius: 0.4rem;
    background-color: #F2F4F8;
    font-size: 0.9rem;
    font-weight: 400;
    border: none;
}

.modalHeader{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 2rem;
}

.modalHeader h2{
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
}

.modalHeader h4{
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    padding-left: 2rem;
}

.fileInput{
    width: 10rem;
    text-overflow: ellipsis; /* Add an ellipsis to the end of the file name display if it overflows */
}
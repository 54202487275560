.swiperButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 8rem;
    gap: 1rem;
}

.circleButton {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0); /* Background color with opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect for frosted glass effect */
    border: 0.5px solid white /* Optional border */
}

.circleButton:hover{
    background-color: #818181;
}
.download-buttons {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

#android-Button {
  margin-left: 1.5rem;
}

.download-button-style button {
  width: 11rem;
  height: 3rem;
  background-color: #135ce7;
  border-radius: 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.089) 0px 50px 100px -20px,
    #001a8385 0px -2px 6px 0px inset;
  border: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.download-button-style li {
  list-style: none;
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
}

.download-button-style h1 {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 0.5rem;
}


@media (max-width: 1140px) {
  .download-button-style button {
    width: 9rem;
    height: 2.5rem;
  }
  .download-button-style h1 {
    font-size: 0.8rem;
  }
  .download-button-style li {
    font-size: 0.8rem;
  }
}

@media (max-width: 930px) {
  .download-button-style button {
    width: 8rem;
    height: 2.4rem;
  }
  .download-button-style button h1 {
    font-size: 0.7rem;
  }
}

@media (max-width: 430px) {
  .download-button-style button {
    width: 7rem;
    height: 2rem;
  }
  .download-button-style button h1 {
    font-size: 0.6rem;
  }
}

@media (max-width: 320px) {
  .download-button-style button {
    width: 6rem;
    height: 1.9rem;
  }
  .download-button-style button h1 {
    font-size: 0.5rem;
  }
}


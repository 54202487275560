.tableWrapper {
    width: 100%;
}

.tableWrapper::-webkit-scrollbar{
    display: none;
}

table {
    width: 100%;
    border-spacing: 0;

}

table img {
    width: 65px;
    height: 65px;
}

table td, table th {
    padding: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table tr th h2 {

    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.25rem;
    /* identical to box height */
    text-align: left;
    color: #757575;

}

/*tbody tr:hover td {*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    !* identical to box height *!*/
/*    !*text-align: center;*!*/
/*    color: red;*/
/*    background-color: #818181;*/
/*}*/


.faTrash{
    color: blue;
    width: 1rem;
    height: 1rem;
}

.faEdit{
    color: #0338BF;
    width: 1rem;
    height: 1rem;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tableImg{
    width: 4rem;
    height: 4rem;
}

.tdLine {
    width: 1rem;
}

.ant-table {
    font-size: 5px;
}

/* row data */
.ant-table-tbody > tr > td {
    height: 5px;
    padding: 5px;
}



.ant-table-thead > tr > th {
    height: 5px;
    padding: 4px;
}

.ant-table-tbody > tr:hover > td {
    background-color: red;
    cursor: pointer;
}

.hoveredRow > td {
    background-color: red;
    cursor: pointer;
}


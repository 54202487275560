.aboutUs-section {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  justify-content: center;
  padding: 0 10%;
  box-sizing: border-box;
  background-image: url("../../../assets/images/about-us-bg.png");
  background-position: center;
  background-size: cover;
}

/* LEFT SECTION STYLE */

.aboutUs-left-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
}

.aboutUs-left-section img {
  width: auto;
  height: 600px;
}

/* RIGHT SECTION STYLE */

.aboutUs-right-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  animation: moveleft 2s;
  animation-direction: alternate;
  position: relative;
}

.aboutUs-right-section h1 {
  font-size: 2.5rem;
  white-space: nowrap;
  color: white;
  text-align: start;
}

.aboutUs-right-section p {
  font-weight: 400;
  padding-top: 2rem;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  color: rgb(200, 200, 200);
}

/* FOLLOW US SECTION*/

.followUs-section {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.followUs-section h1 {
  font-size: 1rem;
  font-weight: 300;
  color: rgb(122, 122, 122);
}

.followUs-section .line {
  background-color: rgb(122, 122, 122);
  height: 0.01rem;
  width: 30px;
  margin: 0 1rem;
}

.followUs-section li {
  list-style: none;
  margin: 0.2rem;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconStyle {
  border-radius: 50%;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.iconStyle:hover {
  box-shadow: rgba(83, 83, 83, 0.24) 3px 3px 8px;
}
@media (max-width: 1590px) {

  .aboutUs-right-section h1 {
    font-size: 2rem;
  }

  .aboutUs-left-section img {
    width: auto;
    height: 550px;
  }
   .slide-in-right  h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 1140px) {
  .aboutUs-section {
    padding: 1.5rem 9%;
  }
  .aboutUs-right-section h1 {
    font-size: 1.7rem;
  }
  .aboutUs-right-section p {
    font-size: 4rem;
  }
  .aboutUs-left-section img {
    width: auto;
    height: 400px;
  }
  .followUs-section h1 {
    font-size: 0.9rem;
  }
  .followUs-section .line {
    width: 90px;
  }
  .iconStyle {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 930px) {
  .aboutUs-section {
    padding: 1.5rem 9%;
  }
  .aboutUs-right-section h1 {
    font-size: 1.2rem;
  }
  .aboutUs-right-section p {
    font-size: 4rem;
  }
  .aboutUs-left-section img {
    width: auto;
    height: 350px;
  }
  .followUs-section h1 {
    font-size: 0.9rem;
  }
  .followUs-section .line {
    width: 60px;
  }
  .iconStyle {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 800px) {
  .aboutUs-section {
    padding: 1.5rem 9%;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .aboutUs-left-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aboutUs-right-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}



@media (max-width: 583px){
  .aboutUs-right-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
  }
}

@media (max-width: 430px) {
  .aboutUs-section {
    padding: 1.5rem 9%;
  }
  .aboutUs-right-section h1 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .aboutUs-left-section{
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .aboutUs-right-section p {
    font-size: 4rem;
  }
  .aboutUs-left-section img {
    width: auto;
    height: 280px;
  }
  .followUs-section h1 {
    font-size: 0.9rem;
    font-weight: 200;
  }
  .followUs-section .line {
    width: 60px;
  }
  .iconStyle {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 330px) {
  .aboutUs-left-section img {
    width: auto;
    height: 250px;
  }
  .aboutUs-left-section{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aboutUs-right-section h1 {
    font-size: 1rem;
    font-weight: 200;
  }
  .aboutUs-right-section p {
    font-size: 4rem;
  }
  .iconStyle {
    width:auto;
    height: 1rem;
  }
.icons{
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconStyle {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.line{
  display: none;
}
.followUs-section h1 {
  font-size: 0.9rem;
  font-weight: 200;
}
}

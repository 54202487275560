.Header {
    /* position: fixed; */
    top: 0;
    right: 0; 
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:url('../../../assets/images/header_bg.png') ;
    /* background-position: center; */
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* align-items: center; */
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 50px;
}
.divCards{
  font-weight: 300;
  display: grid;
  align-items: center;
  align-content: center;
  padding: 50px;
}
.divLine{
 
  color: white;
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.divHeader{
  float: left;
  height: 2rem;
  width: 10rem;
  margin-top: 1rem;
  margin: "16rem";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 800px) {
       .divLine {
          font-size: 10px;
        }
      }
      .divHeader{
        margin-left: 25px;
      }


      .line {
        width: 100%;
        height: 4rem;
        overflow: hidden;
        padding: 0;
        margin-bottom: 16px;
        animation: 10s anim-lineUp ease-out infinite;
      }
      @keyframes anim-lineUp {
        0% {
          opacity: 0;
          transform: translateY(80%);
        }
        20% {
          opacity: 0;
        }
        50% {
          opacity: 1;
          transform: translateY(0%);
        }
        100% {
          opacity: 1;
          transform: translateY(0%);
        }
      }
.centerDiv {
    width: 95%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.blurOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0 ,0,0.7);
    backdrop-filter: blur(4px);
}

.headerRow {
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerLogo {
    display: flex;
    margin-top: 30px;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.headerLogo h2 {
    font-family: "Cabinet Grotesk";
    color: white;
    font-size: 1.5rem;
    font-weight: 400;
}

.headerLogo img {
    width: 1.7rem;
    height: 1.7rem;
}

.headerRight {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.categoriesDiv{
    display: flex;
    align-items: center;
    gap: 1.2rem;
    height: 20%;
}
.titleSection{
    height: 30%;
}
.subtitleSection {
    height: 30%;
}

.modalSection{
    height: 10%;
    margin-bottom: 60px;
}

.categoriesDiv h4{
    font-family: "Cabinet Grotesk";
    color: #FFFFFF;
    font-size: 0.9rem;
    font-weight: 500;
}

.categoriesBox {
    color: white;
    width: 6.5rem;
    height: 1.5rem;
    background-color: #1588FF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.categoriesBox h4 {
    font-family: "Cabinet Grotesk";
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
}

.imageRowNews {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap:1rem;
    overflow: hidden;
    height: 100%;
}

.mainRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainRow h1 {
    font-family: "Cabinet Grotesk";
    font-weight: 800;
    font-size: 2rem;
    color: #FFFFFF;
    align-self: flex-start;
    min-height: 3rem;
    max-height: 7.5rem;
    overflow: hidden;
}

.mainRow h1{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2em;
    max-height: 2.4em; /* Adjust based on the line-height and number of lines */
    hyphens: auto;
    word-wrap: break-word;
}



.mainRow h3 {
    font-family: "Cabinet Grotesk";
    font-weight: 400;
    font-size: 1.2rem;
    color: #FFFFFF;
    max-height: 9rem;
}

.circleButton {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0); /* Background color with opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect for frosted glass effect */
    border: 0.5px solid white /* Optional border */
}

.buttonSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    padding-right: 1rem;
}

.mainRowNews {
    overflow: hidden;
    height: 23rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    max-height: 70%;
}

.mainRowNews {
    cursor: pointer;
}

.sourceContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.sourceContainer img {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
}

.sourceContainer h2 {
    font-family: "Cabinet Grotesk";
    font-weight: 700;
    font-size: 1.375rem;
    color: #FFFFFF;
}

.sourceContainer h4{
    font-family: "Cabinet Grotesk";
    font-size: 1.1rem;
    font-weight: 500;
    color: #FFFFFF;
}

.mainImage {
    width: 100%;
    height: 100%;
}
.mainImageRow{
    /*min-width: 36.125rem;*/
    max-width: 36.125rem;
    max-height:17.313rem;
    min-height:14.313rem;
    overflow: hidden;
}

.bottomPart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.sliderArrows{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 5%;
}

.bottomPart h2{
    font-family: Cabinet Grotesk;
    font-size: 1rem;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #FFFFFF;

}

.categoriesBoxCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -0.6rem;
    align-self: center;
    margin-left: -1.5rem;
}
.categoriesBoxCard:hover{
    cursor: pointer;
}

.categoriesCardName{
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    margin-right: 1rem;
    margin-left: -0.7rem;
    min-width: 26%;}

.categoriesCardName h4 {
    color: white;
    font-family: "Cabinet Grotesk";
    font-weight: 200;
    font-size: 1rem;
}

.categoriesPost {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* Number of lines to show */
    color: white;
}

.sourceSaveShareSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    min-height: 10%;
}
.sourceSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem
}

.saveShareSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5em
}
.sourceSection h4{
    font-family: "Cabinet Grotesk";
    font-weight: 500;
    font-size: 1.2rem;
    color: white;
    text-transform: uppercase;
}

.rightSectionNews {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    min-height: 90%;
  
    height: 100%;
}

.isFavoriteIcon :hover{
    cursor: pointer;
    color: #2d60e3;
}


.isNotFavoriteIcon :hover{
    cursor: pointer;
    color: #2d60e3;
}

.saveButton {
    cursor: pointer;
}

:global(.ant-drawer-header-title){
    display: flex;
    justify-content: flex-end;
}

:global(.ant-modal-wrap) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
}
.backButton {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.backButton:hover{
    cursor: pointer;
}

.backButton h2 {
    font-family: "Cabinet Grotesk";
    color: white;
    font-size: 1.2rem;
    font-weight: 200;
}

.styledHamburgerMenu:hover {
    cursor: pointer;
}

.styledSearchInput :global(.ant-input) {
    background-color: #595959;
    border: none;
    height: 2rem;
    color: white;
    text-align: center;
}

.styledSearchInput :global(.ant-input-search-button) {
    background-color: #595959;
    border: none;
    height: 2rem;
}

.styledSearchInput :global(.ant-input-search-button:hover){
   border: none;
    background-color: #595959;
}

.styledSearchInput :global(.anticon svg) {
    font-size: 1rem;
    color: #FFFFFF;
}

.swiperDiv{
    height: 60%;
}

@media screen and (min-width: 300px) and (max-width: 990px) {
    #root{
        height: 100vh;
    }
    .mainRow {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        gap: 3rem;
        max-height: 70vh;
        flex-wrap: nowrap;
    }
    .mainRowNews {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
        height: auto;
    }
    .rightSectionNews {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;
        width: 100%;
        min-height: 40%;
       margin-top: 30px;
    }
    .bottomPart h2{
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
        letter-spacing: -0.03em;
        text-align: left;
    }
    .headerLogo {
        display: flex;
        align-items: center;
        gap: 0.5rem;
       
    }
    .headerLogo h2{
        font-size: 1.2rem;
    }
    .mainRow h1 {
        font-size: 1.2rem;
        min-height: 1rem;
        max-height: 5rem;
    }
    .mainRow h3{
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 1.2rem;
        max-height: 4.8rem; /* Adjust based on the line-height and number of lines */
        hyphens: auto;
        word-wrap: break-word;

        font-weight: 400;
        font-size: 1rem;
    }
    .bottomPart h2{
        font-size: 0.8rem;
        font-weight: 700;
        line-height: 1.5rem;
        letter-spacing: -0.03em;
        text-align: left;
    }
    .imageRowNews {
        width: 100%;
        height: 100%;
        display: flex;

    }
    .categoryPart{
        /*display: none;*/
    }

    .swiperDiv{
        width: 100%;
        height: 160%;
        margin-bottom: 100px;
    }

    .sourceSaveShareSection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 3rem;
        min-height: 2rem;
    }
    .swiperSliderDiv{
        height: 100%;
        overflow: hidden;
    }
    .imageRowNews {
        align-self: center;
    }
   :global(.ant-card .ant-card-body){
       overflow: hidden;
    }
    .backButton {
        margin-top: 0.8rem;
        gap: 0.5rem;
    }
    .backButton h2{
        font-size: 1rem;
    }

    @media screen and (min-height: 300px) and (max-height: 990px) {
        .headerRow {
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .categoriesPost {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2; /* Number of lines to show */
        color: white;
    }
    @media screen and (min-height: 1000px) and (max-height: 1300px) {
        .categoryPart{
            margin-bottom: 5rem;
        }
        .swiperSliderDiv{
            display: flex;
        }
        .swiperDiv{
            width: 100%;
            height: 70%;
        }
    }


}


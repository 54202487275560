.advertisement-section {
  width: 100%;
  min-height: 90vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  box-sizing: border-box;
  background-image: url("../../../assets/images/ads-bg.png");
  background-position: top;
  background-size: contain;
  flex-direction: column;
  flex-wrap: wrap;
}

.advertisementTitle {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

}

.advertisementImages {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  color: white;
  justify-content: center;
  align-items: flex-end;
  text-align: end;
  flex-direction: column;
}

.advertisementImages p {
  font-weight: 350;
  font-size: 1rem;
}

.ads-button {
  margin-top: 2rem;
  width: 11rem;
  height: 2.5rem;
  background-color: #135ce7;
  border-radius: 0.5rem;
  /* border-top-right-radius: 1.3rem;
      border-bottom-left-radius: 1.3rem; */
  box-shadow: rgba(50, 50, 93, 0.089) 0px 50px 100px -20px,
    #001a8385 0px -2px 6px 0px inset;
  border: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
}

button:hover {
  background-color: #242424;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

@media (max-width: 815px) {
  .advertisement-section {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px){
  .advertisement-section{
    padding: 4rem 2rem ;
  }
  .advertisementTitle h1{
    font-size: 1.5rem;
  }

  .advertisementImages p{
    font-size: 0.8rem; 
   }
  
}

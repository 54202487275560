
.postsLists {
 
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #FFFFFF;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.postsListsCard {
  width: 100%;
  display: flex;
  flex-direction: column;

background-color: white;
}

.addArticleButton{
  width: 6.688rem;
  height: 2.438rem;
  margin: 1rem;
  border: none;
  background: #2561F9;
  border-radius: 0;
  align-self: flex-start;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.2rem;
}
.activerow {
  background-color: green;
  /* Add any other styles for active rows */
}

.inactiverow {
  background-color: red;
  /* Add any other styles for inactive rows */
}

.addArticleButton h2{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  color: #FFFFFF;
}



.horizontalLine {
  margin-top: 1rem;
  width: 100%;
  border: 0.031rem solid #DBDBDB;

}

.loadingText {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
}

.postsListCardHeader{
  /* width: 80rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  
}

.postsListTitle{
  margin-left: .8rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
  letter-spacing: 0;
  text-align: center;
 

}

.postsListCardTitles {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  
}

.actionButtons{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  flex-direction: row;
  border-radius: 0;
}

.cancelProps {
  border:none;
  color:red
}

.addByScrappingButton {
border-radius: 0;
/* margin-left: 30px; */
background: linear-gradient(269.6deg, #2561F9 0.35%, #0095FF 99.76%);
}
.modalHeader {
  display: flex;
 flex-direction: column;
  align-items: center;
}

.modalHeader h2{
  font-size: 1.5625rem;
  font-weight: 500;
  color:red;
}

.modalHeader h4{
  width: 26rem;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}


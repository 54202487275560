.appUse-section {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  justify-content: center;
  padding: 0 10%;
  background-image: url("../../../assets/images/appuse-bg.png");
  background-position: bottom;
  background-size: cover;
  box-sizing: border-box;
}

/* LEFT SECTION STYLE */

.appUse-left-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem 0;
}

.appUse-left-section h1 {
  font-size: 3rem;
  white-space: nowrap;
  text-align: start;
}

/* STEPS SECTION STYLE */

.steps-section {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.steps-description {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  flex-wrap: wrap;
}

.steps-description h1 {
  font-size: 1rem;
  margin-left: 1rem;
  padding-right: 0.3rem;
}

.steps-description p {
  font-size: 1rem;
  font-weight: 300;
  color: #555555;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.steps-description li {
  list-style: none;
  color: #242424;
  font-size: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* RIGHT SECTION STYLE */

.appUse-right-section {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #131313;
}

.appUse-right-section img {
  width: auto;
  height: 550px;
}

.circle {
  position: absolute;
  width: 400px;
  height: 400px;
  border: 3px solid white;
  border-radius: 50%;
}

@media (max-width: 1370px) {
  .appUse-section {
    padding: 0 9%;
  }
  .appUse-right-section img {
    width: auto;
    height: 500px;
  }
}


@media (max-width: 1390px) {
  .appUse-section {
    padding: 0 9%;
  }
  .appUse-right-section img {
    width: auto;
    height: 400px;
  }
}


@media (max-width: 1255px) {
  .appUse-section {
    padding: 0 9%;
  }
  .appUse-right-section img {
    width: auto;
    height: 300px;
  }
  .steps-section h1 {
    font-size: 1.5rem;
  }
  .steps-section p {
    font-size: 1rem;
  }
}

@media (max-width: 1225px) {
  .appUse-section {
    padding: 0 9%;
  }
  .appUse-right-section img {
    width: auto;
    height: 300px;
  }
  .steps-section h1 {
    font-size: 1.5rem;
  }
  .steps-section p {
    font-size: 1rem;
  }
  .steps-description li {
    font-size: 1.2rem;
  }
}

@media (max-width: 1200px) {
  .appUse-section {
    padding: 0 9%;
  }
  .steps-description {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .appUse-right-section img {
    width: auto;
    height: 250px;
  }
  .appUse-left-section h1 {
    font-size: 2rem;
  }
  .appUse-left-section p {
    font-size: 0.8rem;
  }
  .steps-section h1 {
    font-size: 1.5rem;
  }
  .steps-section p {
    font-size: 1rem;
  }
  .steps-description li {
    font-size: 1.5rem;
  }
}

@media (max-width: 800px) {
  .appUse-section {
    padding: 1.5rem 9%;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .steps-section h1 {
    font-size: 1.5rem;
  }
  .steps-section p {
    font-size: 1rem;
  }
  .steps-description li {
    font-size: 1.5rem;
  }
  .steps-description {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .steps-section {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .appUse-right-section img {
    width: auto;
    height: 200px;
    padding: 2rem;
  }
}

@media (max-width: 605px) {
  .steps-section h1 {
    font-size: 1rem;
  }
  .steps-section p {
    font-size: 1rem;
  }
  .steps-description li {
    font-size: 1rem;
  }
  .steps-description {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .steps-description {
    margin: 0.5rem 0;
  }
}

@media (max-width: 400px) {

  .appUse-left-section{
    
    padding: 1.5rem 9%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .steps-section h1 {
    font-size: 1rem;
  }
  .steps-section p {
    font-size: 0.8rem;
  }
  .steps-description li {
    font-size: 1rem;
  }
  .appUse-left-section #title{
    font-size: 1.5rem;
  }
  .steps-description {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .appUse-right-section img {
    width: auto;
    height: 300px;
  }
}

@media (max-width: 350px) {
  .appUse-right-section img {
    width: auto;
    height: 250px;
  }
}

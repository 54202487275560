/*!*.ant-popover .ant-popover-inner {*!*/
/*!*    width: 20rem;*!*/
/*!*    height: 8rem;*!*/
/*!*}*!*/

/*.ant-popover .ant-popover-inner-content {*/
/*    padding-top: 1rem;*/
/*    padding-left: 0.5rem;*/
/*    width: 19.5rem;*/
/*    height: 6rem;*/
/*    font-size: 1rem;*/
/*    font-weight: 450;*/
/*}*/

/*.ant-popconfirm .ant-popconfirm-message-title{*/
/*    font-size: 1.1rem;*/
/*}*/
/*.ant-popconfirm .ant-popconfirm-buttons{*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    padding-top: 1.8rem;*/
/*    padding-right: 1rem;*/
/*}*/

/*.ant-popconfirm .ant-popconfirm-buttons button{*/
/*    width: 5rem;*/
/*    height: 1.5rem;*/
/*    font-size: 0.9rem;*/
/*    text-align: center;*/
/*}*/

/*.ant-popconfirm .ant-popconfirm-buttons .ant-btn-primary {*/
/*    background: black;*/
/*    color: white;*/
/*}*/

/*.ant-popconfirm .ant-popconfirm-buttons .ant-btn-default {*/
/*   color: #2D60E3;*/
/*}*/


.ant-input::placeholder{
    color: #878889;
    font-weight: 400;
}

.leftContainer {
    width: 18.75rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    background-color: #1B1C1E;

}

.rightContainer {
    width: auto;
    height: 99vh;
    background-color: #EAEAEA;

}

.logoSide {
    width: 100%;
    height: 12.5rem;
    background-color: #1B1C1E;


    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {

    width: 79%;
    height: 2.813rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

}

.logo img {
    width: 2.813rem;
    height: 2.813rem;
}

.logo h1 {
    font-size: 0.5rem;
    font-weight: 500;
    line-height: 1.875rem;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;

}


.sidebarBottom h3 {

    font-style: normal;
    font-weight: 500;
    font-size: 0.938rem;
    line-height: 1.375rem;
    /* identical to box height */

    text-align: center;
    color: #5E5E5E;
}

.sidebarBottom h2 {
    width: 7.688rem;
    height:1.875rem;

    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
}

.sidebarBottom button {
    width: 5.688rem;
    height: 2.063rem;
    background: #0338BF;
    border-radius: 0.938rem;
    color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: space-evenly;


}

.sidebarBottom button img {
    width: 0.776rem;
    height: 0.776rem;
}

.sidebarBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3.125rem;
}
.sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.rightContainer {
    width: 100%;
}

.postsHeader {
    height: 99px;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.postsHeader h1{

    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.875rem;
    /* identical to box height */
    text-align: center;
    color: #393D43;
    padding-left: 3.125rem;
}

.postsHeader h2 {

    width: 14.688rem;
    height: 1.25rem;


    font-style: normal;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 1.25rem;
    /* identical to box height */
    text-align: center;
    color: #393D43;
    padding-left: 3.125rem;
}

.postsLists {
    margin-top: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 90vh;
}

.postsListsCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 200px;

}

.addArticleButton{
    width: 6.688rem;
    height: 2.438rem;
    margin: 1rem;
    border: none;
    background: #2561F9;
    border-radius: 0.625rem;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.2rem;
}

.addArticleButton h2{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    color: #FFFFFF;
}



.horizontalLine {
    margin-top: 1rem;
    width: 100%;
    border: 0.031rem solid #DBDBDB;
}

.loadingText {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
}

.postsListCardHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
   
}

.postsListTitle{
    margin-left: .8rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    letter-spacing: 0;
    text-align: center;
}


.postsListSubtitle{
    margin-left: 2.5rem;
    font-size: .813rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0;
    text-align: center;
}

.postsListCardTitles {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*.trashImageList{*/
/*    margin-left: 1rem;*/
/*    width: 1.3rem;*/
/*    height: 1.3rem;*/
/*}*/

/*.editImageList{*/
/*    margin-left: 0.5rem;*/
/*    width: 1.6rem;*/
/*    height: 1.6rem;*/
/*}*/


.actionButtons{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    flex-direction: row;
}

.cancelProps {
    color: #2561F9;
    border: 1px solid #2561F9;
}

.addByScrappingButton {
    margin-left: 1rem;
    background-color: #2561F9;
}

.addButton {
    background-color: #2561F9;
}

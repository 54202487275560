.mainDiv{
    background-color:black;
}
.formDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
}
.registerTitle h4{
    font-family: "Cabinet Grotesk";
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.25rem;
    color:red;
}

.styledInput {
    margin-bottom: 0.5rem;
}

.styledInput label {
    color: #FFFFFF !important;
    font-size: 1.25rem !important;
    font-family: "Cabinet Grotesk" !important;
    font-weight: 200 !important;
    line-height: 1.25rem !important;
    padding-bottom: -1rem !important;
}

.styledInput label:before {
    content: none !important;
}

.styledInput input {
    height: 2rem !important;
    border-radius: 0.2rem !important;
    background-color: #262727 !important;
    border: none !important;
    color: white;
}

.styledInput input::placeholder {
    font-family: "Cabinet Grotesk" !important;
    font-weight: 200 !important;
    font-size: 1rem !important;
    background-color: #262727 !important;
}

.styledInput :global(.ant-input-affix-wrapper) {
    background-color: #262727;
    border-radius: 0.2rem;
    border: none;
    color: white;
}

.styledInput :global(.ant-input-password-icon) svg{
    fill: white;
}

.formDiv :global(.ant-btn-primary:disabled) {
    color: #fff;
    background-color: #262727;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    border: none;
}
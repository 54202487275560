.layout{
    display: flex;
    /* align-items: center; */
    padding: 0;
   margin: 0;

}
/* @media only screen and (min-width: 1000px){
    .Content {
        width: 100%;
        margin-left: 12.5rem;
        margin-top: 3rem;
        height: 100%;
        background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

    }
} */





.footerSection {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: #131313;
  color: white;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.firstSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.firstSection p {
  text-align: center;
  line-height: 2rem;
  color: white;
  display: flex;
  flex-wrap: wrap;
  font-weight: 200;
}

.firstSection h1 {
  font-size: 60px;
  color: white;
}

.footerSection img {
  height: 70px;
}

.footerIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.footerIcons li {
  padding: 15px 40px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset,
    rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset,
    rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px,
    rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px,
    rgba(0, 0, 0, 0.09) 0 0 8px, rgba(0, 0, 0, 0.09) 0 0 0;
}

.footerIcons li:hover {
  transform: scale(1.1);
}

.secoundSection p {
  font-size: 18px;
  color: #ebebeb;
  font-weight: 250;
  padding-left: 20px;
}
.rights{
  padding: 30px 0;
}
.p-terms{
  text-decoration: underline;
}

.p-terms:hover{
  cursor: pointer;
}

.secoundSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.p-terms{
  padding: 30px 0;
}

@media (max-width: 720px) {
  .footerSection {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 1.5rem 8%;
  }

  .firstSection h1 {
    font-size: 3rem;
  }
  .firstSection p {
    font-size: 1.2rem;
    font-weight:00;
    text-align: center;
  }
  .secoundSection h2{
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .firstSection p {
    font-size: 1rem;
    font-weight:200;
    text-align: center;
  }
  .secoundSection h2{
    font-size: 0.5rem;
  }
  
}

@media (max-width: 380px) {
  .firstSection p {
    font-size: 1rem;
    font-weight:100;
    text-align: center;
  }
  .secoundSection h2{
    font-size: 0.5rem;
  }
  .firstSection h1 {
    font-size: 2rem;
  }
}


a {
  color: white;
  font-weight: 200;
}
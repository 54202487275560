
.modalHeader{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;

}

.modalHeader h2{
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
}

.modalHeader h4{
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    padding-left: 2rem;
}


.firstRow label span{
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.titleArea {
    resize: none;
    border-radius: 0.2rem;
    width: 45rem;
    height: 3rem;
    padding: 0.2rem;
    margin-right: 0.5rem;
}

.subtitleArea {
    resize: none;
    border-radius: 0.2rem;
    width: 45rem;
    height: 3rem;
    padding: 0.2rem;
}

.imageUrlArea {
    resize: none;
    border-radius: 0.2rem;
    width: 40rem;
    height: 3rem;
    border-color: #61dafb;
    padding: 0.2rem;
}


.firstRow {
    margin-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.dropdownsSelect {
    height: 5rem;
    display: flex;
    flex-direction: row;


}
.dropdownsSelect label{
    padding-left: 1rem;
}

.dropdownsSelect label span {
    margin-right: 0.5rem;
}

.secondRow {
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: -0.5rem ;
}

.cancelBtn{
    width: 6rem;
    height: 2.5rem;
    margin: 0.8rem;
    border: none;
    background-color: red;
    color: white;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
}

.originalPostUrlArea {
    resize: none;
    border-radius: 0.2rem;
    width: 45rem;
    height: 2.8rem;
    padding: 0.2rem;
}


Form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

}

.formItem Input{
    padding-left: 1rem;
    height: 4rem;
    border-radius: 0.4rem;
    background-color: #F2F4F8;
    font-size: 0.9rem;
    font-weight: 400;
    border: none;
    text-overflow: ellipsis; /* Add an ellipsis to the end of the file name display if it overflows */
}

.selectInput{
    border-radius: 0.3rem;
    background-color: #2561F9;;

}

.selectInput > div > span{
    font-size: 0.8rem;
    color: white;
}

.selectInput span {
    color: white;
}

.fileInput Button {
    border-radius: 0.3rem;
    background-color: #2561F9;;
}

.fileInput Button span{
    color: white;
}

.datePicker{
    display: flex;
    align-self: flex-start;
    padding-left: 1rem;
    margin-top: -0.7rem;
}


[title="Category"] {
    padding: 0 !important;
}

[title="Sources"] {
    padding: 0 !important;
}

[title="File"] {
    padding: 0 !important;
}


.ant-upload-list-item-name {
    font-size: 14px; /* Change the font size to your desired size */
    overflow: hidden; /* Ensure that the file name display doesn't overflow */
    text-overflow: ellipsis; /* Add an ellipsis to the end of the file name display if it overflows */
    white-space: nowrap; /* Ensure that the file name display doesn't wrap onto multiple lines */
}

.fileInput{
    width: 10rem;
    text-overflow: ellipsis; /* Add an ellipsis to the end of the file name display if it overflows */
}

.isValidUrl {
    align-self: flex-start;
    padding-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5em;
}
.mainContainer{
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.phoneContainer {
    position: relative;
    top:20px;
    width: 300px;
    height: 550px;
    background-image: url("../../assets/images/Silver.png");
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    display: flex;
}

.uploadImageBox {
    position: absolute;
    top: 16px;
    object-fit: contain;
    border-radius: 30px;
    left: 37px;
    width: 240px;
    height: 520px;
    background-size: cover;
    background-repeat: no-repeat;
}

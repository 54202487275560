.SidebarLogo {
}

.sideBarLogo img{
    width: 1rem;
    height: 1rem;
}

.menuSider label{
    font-size: 2rem;
}

.logoImage{

}
.customOption {
    display: flex;
    align-items: center;
  }
  
  .customOption img {
    width: 20px;
    height: 15px;
    margin-right: 10px;
  }
.mainDiv{
    background-color: black;
    min-height: 100vh;
}

.headerLogo {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: center;
}
.headerRight {
    width: 33%;
    display: flex;
    justify-content: end;
    align-items: center;
}
.circleButton {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0); /* Background color with opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect for frosted glass effect */
    border: 0.5px solid white /* Optional border */
}
.backTitle :hover{
    cursor: pointer;
}

.backTitle{
    width: 33%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.backTitle h2{
    font-family: "Clash Display", serif;
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    color: white;
}

.headerLogo h2 {
    font-family: "Clash Display", serif;
    color: white;
    font-size: 1.8rem;
    font-weight: 400;
}

.headerLogo img {
    width: 1.8rem;
    height: 1.8rem;

}

.headerDiv {
    height: 10vh;
    margin-top: 2.5%;
    padding-right: 5%;
    padding-left: 5%;
    display: flex;
    justify-content: space-between;
}

.sourceSection {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.confirmButton{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.centerDiv{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
}

.selectSourcesDiv{
    width: 100%;
}

.toggleSwitch {
    margin-top: 4rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: white;
    align-self: flex-end;
}

.toggleSwitch h3 {
    font-family: "Clash Display", serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: #ADADAD;
}

.centerDivHeader{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.centerDivHeader h2{
    font-family: "Clash Display", serif;
    font-size: 1.8rem;
}

.centerDivHeader h3{
    font-family: "Clash Display", serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: #ADADAD;
}

.confirmSection{
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmCard{
    margin-bottom: 1rem;
    background-color: #242424;
    border-radius: 0.2rem;
    border: none;
    height: 3rem;
    padding: 1rem;
}

.confirmCard:hover{
    cursor: pointer;
    background-color: #2d60e3;
}

.sourceCard:hover {
    cursor: pointer;
}

.toggleSwitchCol{
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
}

.searchDiv {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.styledSearchInput :global(.ant-input) {
    background-color: #595959;
    border: none;
    color: white;
    height: 2rem;
    text-align: center;
}

.styledSearchInput :global(.ant-input-search-button) {
    background-color: #595959;
    border: none;
    height: 2rem;
}

.styledSearchInput :global(.ant-input-affix-wrapper) {
    background-color: #595959;
    border-color: #595959;
}

.styledSearchInput :global(.ant-input-search-button:hover){
    border: none;
    background-color: #595959;
}

.infiniteScrollRow {
    margin-top: 1.5rem;
    background-color: black;
    display: flex;
    justify-content: center;
}
.CardLeftDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    min-width: 11rem;
    max-width: 11rem;
    height: 8rem;
}

.styledCard {
    display: flex;
    flex-direction: row;
    max-width: 48rem;
    min-height: 10rem;
    max-height: 10rem;
    background-color: black;
    border-bottom: 1px solid #333333;
    align-items: center;
    overflow: hidden;
}

.styledCard :hover {
    cursor: pointer;
}


.cardText {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
}

.cardText h2{
    font-family: Cabinet Grotesk;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
}

.cardText h3 {
    font-family: Cabinet Grotesk;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    color: #285ADC;


}

.categoryText h4 {
    font-family: Cabinet Grotesk;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    color: #FFFFFF;

}

.categoryText h5{
    font-family: Cabinet Grotesk;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
}
.categoryText{
    display: flex;
    align-items: center;
    gap: 1rem;
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background-color: black;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.saveButton {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.title h2{
    font-family: "Clash Display", serif;
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
}


@media screen and (min-width: 2px) and (max-width: 990px) {
    .mainImage :global(.ant-image-img){
            width: 8rem;
            height: 5rem;
    }
    .CardLeftDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        min-width: 5rem;
        max-width: 5rem;
        height: 3rem;
    }
    .cardText {
        padding: 0.5rem;
        gap: 1rem;
    }

    .cardText h2{
        display: -webkit-box;
        font-size: 0.9rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 1.2em;
        max-height: 2.4em; /* Adjust based on the line-height and number of lines */
        hyphens: auto;
        word-wrap: break-word;
    }
    .cardText h3 {
        font-family: Cabinet Grotesk;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: left;
        text-transform: uppercase;
        color: #285ADC;
    }
    .categoryText h4 {
        font-size: 0.8rem;
    }
    .categoryText h5{
        font-family: Cabinet Grotesk;
        font-size: 0.6rem;
        font-weight: 500;
        text-align: left;
        color: #FFFFFF;
    }
    .backTitle h2 {
        display: none;
    }
    .headerLogo img{
        display: none;
    }
    .headerLogo h2{
        font-size: 1.4rem;
    }
    .title h2{
        font-size: 1rem;
    }
}



.mainDiv{
    background-color: black;
    min-height: 100vh;
}
.mainDiv1{
    background-color: black;
    min-height: 100vh;
}


.headerLogo {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.circleButton {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0); /* Background color with opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect for frosted glass effect */
    border: 0.5px solid white /* Optional border */
}

.backTitle{
    width: 33%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.backTitle :hover{
    cursor: pointer;
}

.backTitle h2{
    font-family: "Clash Display", serif;
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    color: white;
}

.headerLogo h2 {
    font-family: "Clash Display", serif;
    color: white;
    font-size: 1.8rem;
    font-weight: 400;
}

.headerLogo img {
    width: 1.8rem;
    height: 1.8rem;
}

.headerDiv {
    margin-top: 2.5%;
    padding-right: 5%;
    padding-left: 5%;
    display: flex;
    justify-content: space-between;
}

.sourceSection {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.confirmButton{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.centerDiv{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
}

.selectSourcesDiv{
    width: 100%;
}

.toggleSwitch {
    margin-top: 4rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: white;
    align-self: flex-end;
}

.toggleSwitch h3 {
    font-family: "Clash Display", serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: #ADADAD;
}

.centerDivHeader{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.centerDivHeader h2{
    font-family: "Clash Display", serif;
    font-size: 1.8rem;
}

.centerDivHeader h3{
    font-family: "Clash Display", serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: #ADADAD;
}

.confirmSection{
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmCard{
    margin-bottom: 1rem;
    background-color: #242424;
    border-radius: 0.2rem;
    border: none;
    height: 3rem;
    padding: 1rem;
}

.confirmCard:hover{
    cursor: pointer;
    background-color: #2d60e3;
}

.sourceCard:hover {
    cursor: pointer;
}

.toggleSwitchCol{
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
}

@media screen and (min-width: 2px) and (max-width: 990px) {
    .mainDiv{
        overflow-y: auto;
    }
    .centerDivHeader{
        align-items: flex-start;
    }
    .backTitle h2 {
        display: none;
    }
    .headerLogo img{
        display: none;
    }
    .headerDiv{
        margin-top: 2rem;
        justify-content: space-evenly;
    }
   :global(.ant-col-18){
       flex: 0 0 90%;
       max-width: 100%;
   }
    .confirmSection{
        margin-top: 0;
    }
}
/* .selectDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 10px;
    border: none;
    width: 100%;
  } */
 
  
  /* .selectDropdown .my-select-container .ant-select .ant-select-selector {
    border-radius:0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 10px;
    border: none;
    width: 100%;
  } */
  .selectDropdown {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 10px;
    border: none !important;
}

.customOption {
    display: flex;
    align-items: center;
}

.customOption img {
    width: 20px;
    height: 15px;
    margin-right: 10px;
}

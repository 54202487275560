.sitecardwrapper {
    z-index: 0;
    width: 100%;
    display: flex;
   justify-content: space-evenly;
    flex-wrap: wrap;
 }
.ant-card-head {
    background: #E6F5FA;
    border-radius: 0%;
    font-size: 20px;
   
  }
  .ant-card-body {
    background: #E6F5FA;
    border-radius: 0;
    font-size: 15px;
    
  }
 
  .p{
    font-size:20px
  }
  .antCard {
    width: 100%;
  }
  @media (max-width: 900px) {
    .sitecardwrapper {

      
    }
    .p{
      font-size: 10px;
    }
   img#responsive{
    height: 400px;
   }
  }
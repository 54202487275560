.web-section {
  width: 100%;
  height: 100%;
  color: white;
  min-height: 70vh;
  box-sizing: border-box;
  background-color: #131313;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.web-section img {
  height: 40rem;
}

.web-section #web-title {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

/*Button Style */

.webBtn button:hover {
  background-color: #242424;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.webBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 11rem;
  background-color: #135ce7;
  border-radius: 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.089) 0px 50px 100px -20px,
    #001a8385 0px -2px 6px 0px inset;
  border: transparent;
  cursor: pointer;
  margin-top: 2rem;
}

.webBtn h1 {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 1rem;
}

.webBtn li {
  list-style: none;
  display: inline;
  color: white;
  margin-left: 10px;
}

/* Media Query for Tablet Devices */
@media (max-width: 1040px) {
  .web-section #web-title {
    font-size: 2.2rem;
  }
  .web-section p {
    font-size: 1rem;
  }

  .webBtn {
    padding: 8px 16px;
  }

  .webBtn h1 {
    font-size: 0.8rem;
  }

  .web-section img {
    max-width: 90%; /* Adjust as needed */
    height: auto;
  }
}

@media (max-width: 900px) {
  .web-section #web-title {
    font-size: 2.1rem;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
  .web-section #web-title {
    font-size: 1.7rem;
  }

  .web-section p {
    font-size: 0.8rem;
  }

  .webBtn {
    padding: 6px 12px;
  }

  .webBtn h1 {
    font-size: 0.7rem;
  }

  .web-section img {
    max-width: 80%; /* Adjust as needed */
    height: auto;
  }
}

@media (max-width: 470px) {
  .web-section #web-title {
    font-size: 1.3rem;
  }
  .web-section p {
    font-size: 0.7rem;
  }

  .webBtn button {
    padding: 3px 4px;
  }

  .webBtn h1 {
    font-size: 0.9rem;
  }

  .web-section img {
    max-width: 70%; /* Adjust as needed */
  }
}

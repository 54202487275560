
.textInputContainer {
    border-radius: .625rem;
    margin-top: 1.563rem;
    width: 20rem;
    height: 3rem;
    background-color: #F2F2F3;
    border: none;
    display: flex;
    align-items: center;
}

.textInput{
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.688rem;
    border: none;
    padding-left: 1.938rem;
    border-radius: 0.625rem;
    background-color: #F2F2F3;
    width: 100%;
    outline: none;

}

.textInputIcon {
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 1.25rem;
    cursor: pointer;
    color: #818181;

}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import 'assets/styles/ant-design.css';
@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');
@import url("https://api.fontshare.com/v2/css?f[]=clash-display@200,400,700,500,600,300&display=swap");

  .App {
    text-align: center;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Clash Display","Poppins","Cabinet Grotesk", Arial, serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html {
  height: 100vh;
  width: 100%;
}


.mainDiv{
    background-color:black;
    height: 110vh;
    align-items: flex-start;
}

.registerDiv {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    gap: 1.5rem;
}

.registerTitle {
    width: 100%;
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
    align-items: flex-start;
}

.registerTitle h1 {
    font-family: "Clash Display" !important;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #FFFFFF;
}

.registerTitle h4{
    font-family: "Clash Display" !important;
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.25rem;
    color: #FFFFFF;
}

.logInLogo {
    width: 3.75rem;
    height: 3.75rem;
}

.logInButton {
    margin: 1rem;
    height: 3.813rem;
    width: 20rem;
    top: 23.25rem;
    border-radius: 0.2rem;
    background-color: #2D60E3;
    color: #FFFFFF;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 0.938rem;
    line-height: 1.375rem;
    cursor: pointer;
}

.registerForm{
    flex-direction: column;
    display: flex;
    gap: 0.75rem;
    justify-content: space-evenly;
}

.textInput {
    background-color: red;
    color: red;
}

.forgotPassword:hover {
    color: #2d60e3;
    cursor: pointer;
}
.errorText {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

.formItem Input{
    background-color: #F2F4F8;
    text-overflow: ellipsis;
}

.footerRegister {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 70%;
    
}

.footerRegister p {
    color: white;
    font-family: "Clash Display" !important;
    font-size: 1.25rem;
    
}

.termsConditions {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    color: white;
    width:100%;
    padding-top:10px;
  
}


.logoDiv{
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.styledInput {
    margin-bottom: 0.5rem;
}

.styledInput label {
    color: #FFFFFF !important;
    font-size: 1.25rem !important;
    font-family: "Clash Display" !important;
    font-weight: 500 !important;
    line-height: 1.25rem !important;
    padding-bottom: -1rem !important;
}

.styledInput label:before {
    content: none !important;
}

.styledInput input {
    height: 2rem !important;
    border-radius: 0.2rem !important;
    width: 100% !important;
    background-color: #262727 !important;
    border: none !important;
    color: #FFFFFF;
}

.styledInput input::placeholder {
    font-family: "Clash Display" !important;
    font-weight: 400 !important;
    font-size: 0.9rem !important;
    background-color: #262727 !important;
}

.styledInput :global(.ant-input-affix-wrapper) {
    background-color: #262727;
    border-radius: 0.2rem;
    border: none;
}
.styledInput :global(.ant-input-password-icon) svg{
    fill: white;
}

.backButton{
    margin-top: 4rem;
    display: flex;
    align-items: center;
    margin-bottom: -3rem;
    padding-left: 3rem;
    gap: 1rem;
}
.circleButton {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px); /* Apply a blur effect for frosted glass effect */
    border: 0.09rem solid white; /* Optional border */
    background-color: #272727;
    z-index: 1000;
}

.circleButton:hover{
    background-color: #818181;
    cursor: pointer;
}
.backTitle{
    color: white;
}
.backButton h2{
    font-family: "Clash Display" !important;
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    z-index: 1000;
}

.logoTextDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.logoTextDiv h2 {
    font-family: "Clash Display" !important;
    color: white;
    font-size: 2.5rem;
    z-index: 2;
}

.logoTextDiv h3 {
    font-family: "Clash Display" !important;
    font-weight: 400;
    text-align: center;
    line-height: 2rem;
    color: white;
    font-size: 1.25rem;
    z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 900px) {
    #root{
        height: 100vh;
    }
    .lefLogoColumn{
        display: none;
    }
    .backButton{
        margin-top: 2rem;
        display: flex;
        align-items: center;
        margin-bottom: -3rem;
        padding-left: 2rem;
        gap: 1rem;
    }
    .logoDiv{
        display: none;
    }
    .registerDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        gap: 1.5rem;
    }
    .registerTitle h1{
        font-size: 1.5rem;
    }
    .registerTitle h4{
        font-size: 1rem;
    }
    .mainDiv{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow-y: auto;
        gap: 1rem;
    }

}

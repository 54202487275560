.intro-section {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10%;
  box-sizing: border-box;
}

#web-link {
  cursor: pointer;
}

#web-link:hover {
  cursor: pointer;
  color: #135ce7;
}

/* LEFT SECTION STYLE */

.intro-left-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
}

.intro-left-section #intro-title {
  font-size: 3rem;
  white-space: nowrap;
  font-weight: 750;
}

.intro-left-section p {
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: #242424;
  text-align: start;
}

.yellowText {
  color: #fed201;
}

/* LOGO SECTION STYLE */

#logo-section {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

#logo-section h2 {
  font-size: 1rem;
  margin-left: 1rem;
}
#logo-section img {
  height: 1.7rem;
  margin-top: 0;
  width: auto;
}
#web-btn1 {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
#web-btn1 p {
  font-weight: 600;
}
.web-icon {
  margin-left: 1rem;
  margin-top: 0.5rem;
  color: #131313;
}

/* RIGHT SECTION STYLE */

.intro-right-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #131313;
  animation: moveleft 2s;
  animation-direction: alternate;
  position: relative;
}

/* .intro-right-section img {
  width: auto;
  height: 300px;
} */

.intro-right-section img {
  width: auto;
  height: 480px;
  padding: 0 1rem;
}

.contactUS {
  margin-top: 4rem;
}

.contactUS h2 {
  margin-bottom: 0.8rem;
}

.contactUS b {
  display: inline-block;
  margin-bottom: 0.3rem;
}

.contactUS .yellowText {
  padding-right: 0.2rem;
}

.contactUS a {
  color: white;
  text-decoration: none;
}

/* @media (max-width: 1505px) {
  .intro-left-section {
    border: 1px solid red;
  }
  .intro-left-section p {
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: #242424;
    text-align: start;
  }
}

@media (min-width: 500px) and (max-width: 1025px){
  .intro-section {
    border: 1px solid blue;
    padding: 1.5rem 10%;
    grid-template-columns: 1fr;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intro-left-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intro-left-section p{
    text-align: center;
  }
  .intro-right-section img{
    height: 400px;
    width: auto;
  }
  .logo-section{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 505px) and (max-width: 545px){
  .intro-section {
    border: 1px solid blue;
    padding: 1.5rem 25%;
    grid-template-columns: 1fr;
    text-align: start;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .intro-left-section{
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .intro-left-section p{
    text-align: start;
  }
  .logo-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .intro-right-section{
      display: flex;
      justify-content: center;
      align-items: flex-start;
  }
}


@media (min-width: 478px) and (max-width: 505px) {
  .intro-section {
    padding: 1.5rem 10%;
    grid-template-columns: 1fr;
    text-align: start;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .logo-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .intro-right-section img {
    width: auto;
    height: 350px;
  }
  .intro-left-section #intro-title {
    font-size: 2.8rem;
  }
  .intro-left-section p {
    font-size: 1.1rem;
    text-align: start;
  }
  
}

@media (max-width: 477px){
  .intro-section {
    padding: 1.5rem 25%;
    grid-template-columns: 1fr;
    text-align: start;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .intro-left-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .logo-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .intro-left-section #intro-title {
    font-size: 2.2rem;
  }
  .intro-left-section p {
    font-size: 0.9rem;
    text-align: start;
  }
  .intro-right-section img {
    width: auto;
    height: 350px;
  }
} */

@media (max-width: 1600px) {
  .intro-left-section p {
    font-size: 1.5rem;
    padding-right: 5rem;
  }
}
@media (max-width: 1250px) {
  .intro-section {
    padding: 1.5rem 9%;
  }
  .intro-left-section #intro-title {
    font-size: 2.5rem;
  }
  .intro-left-section p {
    font-size: 1rem;
  }
  .intro-right-section img {
    width: auto;
    height: 600px;
    padding: 0 1rem;
  }
}

@media (max-width: 1140px) {
  .intro-section {
    padding: 1.5rem 9%;
  }
  .intro-left-section #intro-title {
    font-size: 2rem;
  }
  .intro-left-section p {
    font-size: 1rem;
  }
  .intro-right-section img {
    width: auto;
    height: 450px;
    padding: 0 1rem;
  }
}

@media (max-width: 930px) {
  .intro-section {
    padding: 1.5rem 9%;
  }
  .intro-left-section {
    padding-right: 0rem;
  }
  .intro-left-section #intro-title {
    font-size: 2rem;
  }
  .intro-left-section p {
    font-size: 0.8rem;
  }
  .intro-right-section img {
    width: auto;
    height: 400px;
    padding: 0 1rem;
  }
}

@media (max-width: 800px) {
  .intro-left-section {
    padding-right: 0rem;
  }
  .intro-section {
    padding: 1.5rem 9%;
    grid-template-columns: 1fr;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intro-left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0rem;
  }
  .logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: none;
    margin: none;
  }
  .intro-left-section #intro-title {
    font-size: 2rem;
  }
  .intro-left-section p {
    font-size: 1rem;
    text-align: center;
    padding-right: 0;
  }
  .intro-right-section img {
    width: auto;
    height: 400px;
    padding: 1rem;
  }
}

@media (max-width: 430px) {
  .intro-left-section {
    padding-right: 0rem;
    padding-top: 2rem;
  }
  .intro-left-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .logo-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .intro-left-section {
    padding-right: 0rem;
    margin: none;
    display: flex;
    text-align: start;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .intro-left-section #intro-title {
    font-size: 1.8rem;
  }

  .intro-left-section p {
    font-size: 0.9rem;
    text-align: start;
  }
  .intro-right-section img {
    width: auto;
    height: 300px;
    padding: 1rem;
  }
}

@media (max-width: 450px) {
  .intro-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro-left-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .logo-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .logo-section h1 {
    font-size: 0.8rem;
  }
  .intro-left-section #intro-title {
    text-align: start;
    font-size: 1.8rem;
  }
  .intro-left-section p {
    font-size: 0.9rem;
    text-align: start;
  }
  .intro-right-section {
  }

  .intro-right-section img {
    width: auto;
    height: 350px;
  }
  .logo-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 330px) {
  .intro-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-section h1 {
    font-size: 0.8rem;
  }
  .intro-left-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .logo-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .intro-left-section #intro-title {
    text-align: start;
    font-size: 1.5rem;
  }
  .intro-left-section p {
    font-size: 0.8rem;
    text-align: start;
  }

  .intro-right-section img {
    width: auto;
    height: 250px;
    padding: 0rem;
  }
}





.firstRow {
    margin-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}


.formItem{
    width: 45rem;
    height: 4rem;

}

.formItem Input{
    padding-left: 1rem;
    height: 4rem;
    border-radius: 0.4rem;
    background-color: #F2F4F8;
    font-size: 0.9rem;
    font-weight: 400;
    border: none;
}

.inputArea {
    resize: none;
    border-radius: 0.2rem;
    width: 45rem;
    height: 3rem;
    padding: 0.2rem;
    margin-right: 0.5rem;
}

.fileInput Button {
    border-radius: 0.3rem;
    background-color: #2561F9;;
}

.fileInput Button span{
    color: white;
}


.modalHeader{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 2rem;
}

.modalHeader h2{
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
}

.modalHeader h4{
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    padding-left: 2rem;
}

.sourceForm {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fileInput{
    width: 10rem;
    text-overflow: ellipsis; /* Add an ellipsis to the end of the file name display if it overflows */
}

.uploadFile {
    color: #2d60e3;
}
.scrollToTop button{
    width: 2rem;
    height: 2rem;
    background-color: #135ce7;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.089) 0 50px 100px -20px,
      #001a8385 0 -2px 6px 0 inset;
    border: transparent;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .scrollToTop button:hover{
    transform: scale(1.1);
  }

  @media (max-width: 1250px){
    .scrollToTop button{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
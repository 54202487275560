.logIn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
}

.logIn h1 {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2.375rem;
    width: 11.375rem;
}

.logInLogo {
    width: 3.75rem;
    height: 3.75rem;
}

.logInButton {
    margin-top: 1rem;
    height: 3.813rem;
    width: 20rem;
    top: 23.25rem;
    border-radius: 0.938rem;
    background-color: #2D60E3;
    color: #FFFFFF;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 0.938rem;
    line-height: 1.375rem;
    cursor: pointer;
}

.forgotPassword{
    color: #0095FF;
}

.forgotPasswordSection{
    display: flex;
    align-self: flex-end;
}

.forgotPassword:hover {
    color: #2d60e3;
    cursor: pointer;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 0.8rem;
}

.forgotCreateSection {
    width: 98%;
    display: flex;
    justify-content: space-between;
}

.createAccount{
    color: #0095FF;
}

.createAccount:hover {
    color: #2d60e3;
    cursor: pointer;
}

.errorMessage{
    color: red;
    font-size: 1rem;
}

.logoDiv{
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainDiv{
    background-color:black;
    height: 110vh
}
.LoginDiv {
 
    gap: 1.5rem;
    position: relative; /* Needed for the absolute positioning to work inside this container */
    width: 70%;
    height: 100vh; /* Full height to ensure proper centering */
    display: flex;
    flex-direction: column;
    justify-content: center
   
}


.LoginTitle {
    width: 80%;
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
    justify-content: left !important;}


.LoginTitle h1 {
    font-family: "Clash Display" !important;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #FFFFFF;
}

.LoginTitle h4{
    font-family: "Clash Display" !important;
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 1.25rem;
    color: #FFFFFF;
}

.footerLogin {
    position:static; /* Position the element absolutely */
 
    text-align: center; /* Center the text inside the container */
    width: 100%; /* Take the full width */
    display: flex;
    justify-content: center;
    align-items: center;
}


.footerLogin p{
    color: white;
    font-family: "Clash Display" !important;
    font-size: 1.25rem;
    
}



.styledInput {
    margin-bottom: 0.5rem;
    font-family: "Clash Display";
}

.styledInput label {
    color: #FFFFFF !important;
    font-size: 1.25rem !important;
    font-family: "Clash Display" !important;
    font-weight: 500 !important;
    line-height: 1.25rem !important;
    padding-bottom: -1rem !important;
}

.styledInput label:before {
    content: none !important;
}


.styledInput input {
    height:2.5rem !important;
    border-radius: 0.2rem !important;
    background-color: #262727 !important;
    resize: none;
    color: white !important;
}

.styledInput input::placeholder {
    font-family: "Clash Display" !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    background-color: #262727 !important;
}

.styledInput :global(.ant-input-affix-wrapper) {
    height: 2.5rem;
    display: flex;
    align-items: center;
    background-color: #262727;
    border-color: #262727;
    border-radius: 0.2rem;
}

.styledInput :global(.ant-input-password-icon) svg{
   fill: white;
}
.backButton{
    margin-top: 4rem;
    display: flex;
    align-items: center;
    margin-bottom: -3rem;
    padding-left: 3rem;
    gap: 1rem;
}


.circleButton {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    border: 0.09rem solid white;
    background-color: #272727;
    z-index: 1000;
}

.circleButton:hover{
    background-color: #818181;
    cursor: pointer;
}
.backTitle{
    color: white;
}

.backButton h2{
    font-family: "Clash Display" !important;
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    z-index: 1000;
}

.logoTextDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.logoTextDiv h2 {
    font-family: "Clash Display" !important;
    color: white;
    font-size: 2.5rem;
    z-index: 2;
}

.logoTextDiv h3 {
    font-family: "Clash Display" !important;
    font-weight: 400;
    text-align: center;
    line-height: 2rem;
    color: white;
    font-size: 1.25rem;
    z-index: 2;
}

@media screen and (min-width: 0px) and (max-width: 900px) {
    .lefLogoColumn{
        display: none;
    }
    #root{
        height: 100vh;
    }

    .backButton{
        margin-top: 2rem;
        display: flex;
        align-items: center;
        margin-bottom: -3rem;
        padding-left: 2rem;
        gap: 1rem;
    }
    .LoginDiv :global(.ant-form){
        overflow: hidden;
    }
}

::-ms-reveal {
    display: none
}

.modalHeader {
    display: flex;
   flex-direction: column;
    align-items: center;
}

.modalHeader h2{
    font-size: 1.5625rem;
    font-weight: 500;
    color:red;
}

.modalHeader h4{
    width: 26rem;
    font-size: 1.125rem;
    font-weight: 300;
    text-align: center;
}
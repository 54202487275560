.categoriesList {
     margin-top: 2.5rem;
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     align-items: center;
     min-height: 90vh;
}

.categoriesCard {
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
}

.addArticleButton {
     width: 8rem;
     height: 2.438rem;
     margin: 1rem;
     border: none;
     background: #2561F9;
     border-radius: 0.625rem;
     align-self: flex-start;

     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
     align-items: center;
     padding: 0.2rem;
}

.addArticleButton h2 {
     font-weight: 400;
     font-size: 0.9rem;
     line-height: 20px;
     /* identical to box height */
     text-align: center;
     color: #FFFFFF;
}

.faPlus {
     color: white;
     width: .8rem;
     height: .8rem;
}

.horizontalLine {
     margin-top: 1rem;
     width: 100%;
     border: 0.031rem solid #DBDBDB;
}

.loadingText {
     font-size: 1rem;
     font-weight: 500;
     letter-spacing: 2px;
     text-align: center;
}

.categoriesCardHeader {
     width: 100%;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     padding: 1.5rem;
}

.categoriesCardTitles{
     display: flex;
     flex-direction: row;
     align-items: center;
}

.categoriesListTitle {
     margin-left: .8rem;
     font-size: 1.25rem;
     font-weight: 600;
     line-height: 1.875rem;
     letter-spacing: 0;
     text-align: center;
}


.categoriesListSubtitle{
     margin-left: 2.5rem;
     font-size: .813rem;
     font-weight: 400;
     line-height: 1.25rem;
     letter-spacing: 0;
     text-align: center;
}

.cancelButtonstest{
     color: red;
}

.actionButtons{
     display: flex;
     flex-wrap: nowrap;
     justify-content: space-evenly;
     flex-direction: row;
}

.AddCategory{
     background-color: #2561F9;
}

.column-nr {
     width: 3rem;
}

.column-image{
     width: 15rem;
}